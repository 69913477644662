import React, { useState } from 'react';
import next from '../../assets/next.png'
import uparrow from '../../assets/uparrow.png'

const AccordionItem = ({ title, content }) => {
    const [isActive, setIsActive] = useState(false);

    const handleClick = () => setIsActive(!isActive);

    return (
        <div className={`accordion-item ${isActive ? 'active' : ''}`}>
            <button className="accordion-title" onClick={handleClick}>
                <div>{title}</div>
                <div>
                    {!isActive ?
                        <div style={{ width: '20px' }}>
                            <img style={{ width: '100%' }} src={next} alt="collapse" />
                        </div>
                        :
                        <div style={{ width: '20px' }}>
                            <img style={{ width: '100%' }} src={uparrow} alt="expanded" />
                        </div>
                    }
                </div>
            </button>
            <div className="accordion-content" aria-expanded={isActive}>
                <p>{isActive && content}</p>
            </div>
        </div>
    );
};

const Accordion = ({ items }) => {
    return (
        <div className="accordion">
            {items.map((item) => (
                <AccordionItem key={item.title} {...item} />
            ))}
        </div>
    );
};

export default Accordion;