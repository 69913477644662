import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { API } from '../../service/api';

import Shipraxbgromoved from '../../assets/Shipraxbgromoved.png';
import 'react-toastify/dist/ReactToastify.css';

const initialvalues = {
    yourName: '',
    email: '',
    password: '',
    pickUpAddress: '',
    PANNumber: '',
    address: '',
    GSTIN: '',
    file: 'adity',
    gstCertificate: 'singh'
}

const SignUp = () => {

    const navigate = useNavigate();

    const [errorMsg, setErrorMsg] = useState("");

    const [signUp, setSignUp] = useState(initialvalues);

    const [file, setFile] = useState("");
    const [gstCertificate, setGSTCertificate] = useState("");

    useEffect(() => {
        const getfile = async () => {
            if (file) {
                const data = new FormData();
                data.append("name", file.name);
                data.append("file", file);

                // API Call
                const response = await API.uploadFile(data);
                
                console.log('response: ', response);
                signUp.file = response.data;
            }
        }
        getfile();
    }, [file])

    useEffect(() => {
        const getgst = async () => {
            if (gstCertificate) {
                const data = new FormData();
                data.append("name", gstCertificate.name)
                data.append("file", gstCertificate)


                // const response = await API.uploadGst(data)
                signUp.gstCertificate = data
            }
        }
        getgst()
    }, [gstCertificate])

    const handleChange = (event) => {
        const fieldName = event.target.name;
        setSignUp({
            ...signUp,
            [fieldName]: event.target.value,
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!signUp.yourName || !signUp.email || !signUp.password || !signUp.pickUpAddress || !signUp.PANNumber) {
            toast.error('Please fill in all required fields.');
            return;
        }

        try {
            let response = await API.userSignup(signUp);
            console.log('response: ', response);

            if (response.status === 200) {
                toast.success('Signup successful!');

                // Optionally redirect user to another page
                navigate('/login');
            } else {
                toast.error('Something went wrong, please try again later.');
            }
        } catch (error) {
            toast.error('An error occurred, please try again later.');
        }
    }

    return (
        <>
            <div className='signUpWrapper'>
                <a href="" className='logo-container' >
                    <img style={{ marginLeft: '-10px', width: '500px' }} src={Shipraxbgromoved} alt="shiprax" />
                </a>
                <div className='signUplogoWrapper'>
                    <h2 style={{ fontSize: "30px" }}>Sign Up</h2>
                </div>
                <form className='signUpFormWrapper' >
                    <div style={{ width: '45%', display: 'flex', flexDirection: 'column' }}>
                        <label for="yourName"> Your Name *</label>
                        <input
                            type="text"
                            placeholder="Enter Your Name"
                            name="yourName"
                            value={signUp?.yourName}
                            onChange={handleChange}
                        />

                        <label for="email"> Email * </label>
                        <input
                            type="text"
                            placeholder="Enter Your Email"
                            name="email"
                            value={signUp?.email}
                            onChange={handleChange}
                        />

                        <label for="password">Create Your Password *</label>
                        <input
                            type="text"
                            placeholder="Create Your Password"
                            name="password"
                            value={signUp?.password}
                            onChange={handleChange}
                        />

                        <label for="pickUpAddress">Your PickUp Address*</label>
                        <input
                            type="text"
                            placeholder="Enter Your PickUp Address*"
                            name="pickUpAddress"
                            value={signUp?.pickUpAddress}
                            onChange={handleChange}
                        />

                        <label for="PANNumber">Your PAN Number*</label>
                        <input
                            type="text"
                            placeholder="Enter Your PAN Number"
                            name="PANNumber"
                            value={signUp?.PANNumber}
                            onChange={handleChange}
                        />
                    </div>

                    <div style={{ width: '45%', display: 'flex', flexDirection: 'column' }}>
                        <label for="address">Your Address</label>
                        <input
                            type="text"
                            placeholder="Enter Your Address"
                            name="address"
                            value={signUp?.address}
                            onChange={handleChange}
                        />

                        <label for="GSTIN">Your GSTIN (Optional)</label>
                        <input
                            type="text"
                            placeholder="Enter Your GSTIN"
                            name="GSTIN"
                            value={signUp?.GSTIN}
                            onChange={handleChange}
                        />

                        <label htmlFor="file">
                            Upload your PAN Card *
                        </label>
                        <input
                            type="file"
                            id="file"
                            onChange={(e) => setFile(e.target.files[0])}
                        />

                        <label htmlFor="gstCertificate" class="upload-button">
                            <i class="fas fa-upload"></i>
                            Upload Your GST Certificate (Optional)
                            <input
                                type="file"
                                id="gstCertificate"
                                onChange={(e) => setGSTCertificate(e.target.files[0])}
                            />
                        </label>
                    </div>

                    <div style={{ width: '100%', justifyContent: 'center' }} className='loginbtnWrapper'>
                        <button className='btnStyle' type="submit" onClick={e => handleSubmit(e)} style={{ marginRight: '20px', marginTop: '-15px' }}>Sign Up</button>
                    </div>

                    {errorMsg && <div style={{ color: 'red', marginTop: '20px' }}>
                        {errorMsg}
                    </div>}
                </form>
            </div >
        </>
    )
}

export default SignUp