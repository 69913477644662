import React from 'react'
import whatsapp from '../../assets/whatsapp.png';

function MessageOnWhatsApp() {
    return (
        <div style={{position: 'fixed', right: '-77px', bottom: '-12px'}}>
            <div className='whatsAppBtn' onClick={() => window.open(`https://api.whatsapp.com/send?phone=${6398450361}&text=${"Book Your Shipment via Whats App"}`)}>
                <img width="10%" src={whatsapp} />
                <span style={{fontSize: '14px', fontWeight: '600', marginLeft: '5px'}}>Message Us on WhatsApp</span>
            </div>
        </div>
    )
}

export default MessageOnWhatsApp
