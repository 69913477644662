// API_NOTIFICATION_MESSAGES

export const API_NOTIFICATION_MESSAGES = {
    loading: {
        title: 'Loading...',
        message: 'Data is being loaded, Please Wait'
    },
    success: {
        title: 'Success...',
        message: 'Data Successfully loaded'
    },
    responseFailure: {
        title: 'Error...',
        message: 'An error occured while fetching response from the server. Please try again'
    },
    requestFailure: {
        title: 'Error...',
        message: 'An error occured while parsing request data'
    },
    networkError: {
        title: 'Error...',
        message: 'Unable to connect with the server. Please check the internet connection and try'
    },
}

//API SERVICE CALL
// SAMPLE REQUEST
// NEED SERVICE CALL: {URL: '/', method: 'POST/GET/PUT/DELETE' params: true/false, query: true/false}
export const SERVICE_URLS = {
    userSignup: { url: '/signup', method: 'POST' },
    userLogin: { url: '/login', method: 'POST' },

    findEmail: { url: '/findEmail', method: 'POST' },
    resetPassword: { url: '/resetPassword', method: 'POST' },
    
    abcForm: { url: '/cxFormUpload', method: 'POST' },

    // Upload files not working rn
    uploadFile: { url: '/file/uplaods', method: 'POST' },
}