import React, { useRef } from 'react'
import Header from '../../genericComponent/Header';
import TrackYourShiprax from './TrackYourShiprax';
import AboutUs from './AboutUs';
import Services from './Services';
import CustomerReview from './CustomerReview';
import FAQ from './FAQs';
import Footer from '../Dashboard/Footer';
import { useNavigate } from 'react-router-dom';
import MessageOnWhatsApp from '../../genericComponent/MessageOnWhatsApp';

const backgroundStyle = {
  backgroundImage: `linear-gradient(to bottom, rgba(4,14,51,.682), rgba(4,14,51,.682)), 
url(https://www.xhipment.com/static/media/backgroungImg.e1932356ad8a900411eb.png)`,
  backgroundSize: "cover",
  height: "100vh",
  backgroundColor: "rgba(4,14,51,.682)",
}

const Dashboard = () => {

  const navigate = useNavigate();

  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);
  const ref5 = useRef(null);
  const ref6 = useRef(null);

  function handleAboutClick() {
    ref1.current?.scrollIntoView({ behavior: 'smooth' });
  }

  const handleServicesClick = () => {
    ref2.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const handleFAQsClick = () => {
    ref3.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const handleSignUpClick = () => {
    ref4.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const handleTrackClick = () => {
    ref5.current?.scrollIntoView({ behavior: 'smooth' });
  };



  const headerItem = [
    {
      name: 'About Us',
      event: handleAboutClick
    },
    {
      name: 'Services',
      event: handleServicesClick
    },
    {
      name: 'FAQs',
      event: handleFAQsClick
    },
    // {
    //   name: 'Sign Up',
    //   event: handleSignUpClick
    // },
    {
      name: 'Track Your Shiprax',
      event: handleTrackClick
    }
  ]

  function handleNavigate(e) {
    e.preventDefault()
    navigate('/CXForm')
  }

  return (
    <div className='mainWrapper' style={backgroundStyle}>
      <Header headerItem={headerItem} />

      <main className="main-content">
        <div className="img-overlay">
        </div>
      </main>

      <section className="header-content">
        <div className="wrapOne">
          <h2>Welcome to Shiprax</h2>
        </div>

        <div className="wrapTwo">
          <p>Your Satisfaction Is Our Priority
            {/* <span style={{}}> {" "}India based exporters </span> */}
          </p>
          {/* <p className="mt-2">
            Click <a onClick={handleSignUpClick} href="#signup" style={{ color: '#fd7e14', textDecoration: 'none' }}>here</a> to express your interest.</p>
          <br /> */}
          {/* <p className="small-heading mt-2">Partners with</p> */}

          {/* <div className="amazon_logo ">
            <div className="LogoContainer">
              <a href="https://sellercentral.amazon.in/gspn/provider-details/International%20Shipping/37844545-5d80-4530-b890-25e6d9e454ba?ref_=sc_gspn_islst_isdt-37844545&amp;localeSelection=en_US&amp;sellFrom=IN&amp;sellIn=IN" target="blank" >
                <img className="amazon_spn_logo " src={amazonspn} alt="Amazon SPN Logo" />
              </a>
            </div>
          </div> */}

          <button className='btnStyle' style={{ marginTop: '30px', width: '200px' }} onClick={(e) => handleNavigate(e)}> Book Your Shipment </button>
        </div>
      </section >


      <section ref={ref5}>
        <TrackYourShiprax />
      </section>


      <section ref={ref1} >
        <AboutUs />
      </section>


      <section ref={ref2}>
        <Services />
      </section>

      <section>
        <CustomerReview />
      </section>

      <section ref={ref3}>
        <FAQ />
      </section>

      {/* <section ref={ref4}>
        <SignUp />
      </section> */}

      <section>
        <Footer
          onClickAbout={handleAboutClick}
          onClickServices={handleServicesClick}
          onClickTrack={handleTrackClick}
          onClickFAQ={handleFAQsClick}
        />
      </section>
    </div >
  )
}

export default Dashboard
