import LoginPage from './components/LoginPage';
import { Routes, Route } from 'react-router-dom';
import ScrollToTop from './genericComponent/ScrollToTop';
import ForgotPassword from './components/LoginPage/ForgotPassword';
import CXForm from './components/Dashboard/CXForm';
import DataProvider from './components/context/DataProvider';

import './App.css';
import { useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SignUp from './components/SignUp/SignUp';
import Dashboard from './components/Dashboard'
import TrackYourShiprax from './components/Dashboard/TrackYourShiprax';
const PrivateRoute = ({ isAuthenticated, ...props }) => {
  return isAuthenticated ? <Outlet /> : <Navigate replace to="/Login" />
}

function App() {

  const [isAuthenticated, isUserAuthenticated] = useState(false);


  return (
    <div className="App">

      <DataProvider>

      <Routes>
        <Route element={<PrivateRoute isAuthenticated={isAuthenticated} />}>
          <Route path="/" element={<Dashboard />} />
        </Route>

        <Route path="/CXForm" element={<CXForm />} />
        <Route path="/Login" element={<LoginPage isUserAuthenticated={isUserAuthenticated} />} />
        <Route path="/ForgotPassword" element={<ForgotPassword />} />
        <Route path="/Signup" element={<SignUp />} />
        <Route path="/TrackYourShipment" element={<TrackYourShiprax />} />

      </Routes>

      </DataProvider>
      <ToastContainer />
      <ScrollToTop />
    </div>
  );
}

export default App;
