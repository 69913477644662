import React from 'react'
import like from '../../assets/like.svg';
import handshake from '../../assets/handshake.png';
import map from '../../assets/map.png';

function AboutUs() {
    return (
        <div className='aboutUSContainer'>
            <div>
                <h2 className='aboutUSHeading'>
                    We partner with you through each step of the process
                </h2>
                <span className="section-title-line"></span>
            </div>

            <div className='aboutSection'>
                <div className='cardWrapper'>
                    <div className='card'>
                        <div className='iconSection'>
                            <img className='icon' src={like} alt="like" />
                            <h4> Simple </h4>
                            <span className="iconSectionLine"></span>
                        </div>
                        <div className='textSection'>
                            <p>
                                Book, track and manage payments for your shipments. For any help, your Customer Success Expert is a call away.
                            </p>
                        </div>
                    </div>

                    <div className='card'>
                        <div className='iconSection'>
                            <img className='icon' src={handshake} alt="handshake" />
                            <h4> Reliable </h4>
                            <span className="iconSectionLine"></span>
                        </div>
                        <div className='textSection'>
                            <p>
                                Our team of experts work with you to ensure that your shipments are delivered correctly and on time.
                            </p>
                        </div>
                    </div>

                    <div className='card'>
                        <div className='iconSection'>
                            <img className='icon' src={map} alt="map" />
                            <h4> Transparent </h4>
                            <span className="iconSectionLine"></span>

                        </div>
                        <div className='textSection'>
                            <p>
                                Our technology stack catches issues early to resolve them quickly, keeping you updated, ensuring no surprises.
                            </p>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default AboutUs
