import React, { useState } from 'react'
// import { useNavigate } from 'react-router-dom';
import Shipraxbgromoved from '../../assets/Shipraxbgromoved.png';
import { useNavigate } from 'react-router-dom';
import { API } from '../../service/api';
import { toast } from 'react-toastify';

const ForgotPassword = () => {

    const naviagte = useNavigate();
    const [forgotPasswordData, setForgotPasswordData] = useState({
        email: '',
        password: ''
    })

    const [forgotPasswordSuccess, setForgotPasswordSucces] = useState(false)
    console.log('forgotPasswordSuccess: ', forgotPasswordSuccess);


    const handleChange = (event) => {

        const fieldName = event.target.name;

        setForgotPasswordData({
            ...forgotPasswordData,
            [fieldName]: event.target.value,
        });

    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!forgotPasswordData.email) {
            toast.error('Please enter email.');
            return;
        }

        try {
            const response = await API.findEmail({ email: forgotPasswordData.email });
            setForgotPasswordSucces(response.status)

        } catch (error) {
            toast.error('An error occurred, please try again later.');
        }
    };

    const handleRestPassword = async (event) => {
        event.preventDefault();

        if (!forgotPasswordData.password) {
            toast.error('Please enter password.');
            return;
        }

        try {
            const response = await API.resetPassword(forgotPasswordData);

            if (response.status === 200) {
                toast.success("Password Changed Successfully");
                naviagte('/Login');
            } else {
                const errorMessage = response.data?.message || 'Something went wrong, please try again later.';
                toast.error(errorMessage);
            }
        } catch (error) {
            toast.error('An error occurred, please try again later.');
        }
    };


    return (
        <div className='loginWrapper'>
            <a href="" className='logo-container' >
                <img style={{ marginLeft: '-150px', width: '500px' }} src={Shipraxbgromoved} alt="shiprax" />
            </a>
            <div className='logoWrapper'>
                <div style={{ fontSize: "19px" }}>Please enter your email address</div>
            </div>


            <form className='formWrapper' >
                <label for="email">Email Address</label>
                <input
                    type="text"
                    placeholder="Enter Email Address"
                    name="email"
                    value={forgotPasswordData.email}
                    onChange={handleChange}
                    disabled={forgotPasswordSuccess === 200 ? true : false}
                />

                {forgotPasswordSuccess !== false &&
                    <>
                        <label for="password">Enter Your New Password</label>
                        <input
                            type="text"
                            placeholder="Enter Your New Password"
                            name="password"
                            value={forgotPasswordData.password}
                            onChange={handleChange}
                        />
                    </>
                }
                <div className='loginbtnWrapper'>

                    {forgotPasswordSuccess === false ?
                        <button className='btnStyle' type="submit" onClick={e => handleSubmit(e)} style={{ marginRight: '20px' }} >Submit</button>
                        :
                        <button className='btnStyle' type="submit" onClick={e => handleRestPassword(e)} style={{ marginRight: '20px' }} >Reset Password</button>
                    }
                    <button className='btnStyle' onClick={() => naviagte('/Login')} >Log In</button>
                </div>

            </form>
        </div >
    )
}

export default ForgotPassword;
