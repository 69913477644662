import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Shipraxbgromoved from '../../assets/Shipraxbgromoved.png';
import { API } from '../../service/api';
import { DataContext } from '../context/DataProvider'; 
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TrackYourShiprax from '../Dashboard/TrackYourShiprax';

const LoginPage = ({ isUserAuthenticated }) => {
  const navigate = useNavigate();

  const { setAccount } = useContext(DataContext);

  const [loginData, setLoginData] = useState({
    email: '',
    password: '',
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setLoginData({ ...loginData, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!loginData.email || !loginData.password) {
      toast.error('Please enter both email and password.');
      return;
    }

    try {
      const response = await API.userLogin(loginData);
      console.log('response: ', response);


      if (response.status === 200) {
        toast.success("Logged in Successfully");
        const { accessToken, refreshToken, yourName, email } = response.data;
        sessionStorage.setItem('accessToken', `Bearer ${accessToken}`);
        sessionStorage.setItem('refreshToken', `Bearer ${refreshToken}`);
        setAccount({ yourName, email });
        isUserAuthenticated(true);
        navigate('/');
      } else {
        const errorMessage = response.data?.message || 'Something went wrong, please try again later.';
        toast.error(errorMessage);
      }
    } catch (error) {
      toast.error('An error occurred, please try again later.');
    }
  };

  return (
    <>
      <div className="loginWrapper">
        <a href="" className="logo-container">
          <img style={{ marginLeft: '-150px', width: '500px' }} src={Shipraxbgromoved} alt="shiprax" />
        </a>
        <div className="logoWrapper">
          <div style={{ fontSize: '19px' }}>Sign in to your Shiprax account</div>
        </div>

        <form className="formWrapper" onSubmit={handleSubmit}>
          <label htmlFor="email">Email Address</label>
          <input
            type="text"
            placeholder="Enter Email Address"
            name="email"
            value={loginData.email}
            onChange={handleChange}
          />

          <label htmlFor="password">Password</label>
          <input
            type="password"
            placeholder="Enter Password"
            name="password"
            value={loginData.password}
            onChange={handleChange}
          />

          <div className="loginbtnWrapper">
            <button className="btnStyle" type="submit" style={{ marginRight: '20px' }}>Login</button>
            <button className="forgotBtn" onClick={() => navigate('/ForgotPassword')}>Forgot Password?</button>
          </div>
        </form>
      </div>

      <div style={{ display: 'flex', justifyContent: 'space-evenly', marginLeft: '150px' }}>
        <div>Don't have an account ?</div>
        <div style={{ transform: 'translate(-210px, -10px)' }}>
          <button className="btnStyle" onClick={() => navigate('/Signup')}>Sign Up</button>
        </div>
      </div>

      <div style={{ position: 'fixed', top: '50px', right: '50px'}}><button className='btnStyle' style={{width: 'auto', padding: '20px'}} onClick={()=>navigate('/TrackYourShipment')}> Track Your Shipment </button></div>
    </>
  );
};

export default LoginPage;
