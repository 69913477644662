import React from 'react'
import like from '../../assets/like.svg';
import handshake from '../../assets/handshake.png';
import map from '../../assets/map.png';

function CustomerReview() {
    return (
        <div className='aboutUSContainer'>
            <div>
                <h2 className='aboutUSHeading'>
                    Customer Satisfaction: Our Reviews Speak for Themselves
                </h2>
                <span className="section-title-line"></span>
            </div>

            <div className='aboutSection'>
                <div className='reviewCardWrapper'>
                    <div className='reviewCard'>
                        <div className='iconSection'>
                            <h4> "... Competitive pricing for ocean freight ..." </h4>
                            <span className="customerReviewSectionLine"></span>
                        </div>
                        <div className='textSection' style={{ height: "13rem" }}>
                            <p>
                                Shiprax is providing customers with very competitive rates for both LCL and FCL shipments. Very good price with even better service. We feel that Shiprax has filled a huge gap in the market by providing Amazon Sellers with various services.
                            </p>

                        </div>
                        <div className='customerName'>
                            <em>Naina - Handicrafts Exporter</em>
                        </div>
                    </div>

                    <div className='reviewCard'>
                        <div className='iconSection'>
                            <h4> "... Very happy all round Amazon shipment service ..." </h4>
                            <span className="customerReviewSectionLine"></span>
                        </div>
                        <div className='textSection' style={{ height: "13rem" }}>
                            <p>
                                Our experience starting from onboarding to handling to delivery was not just smooth but also well structured. We are also glad with the rate of the shipment in comparison with other shipping agencies. In all very happy all round.
                            </p>


                        </div>
                        <div className='customerName'>
                            <em>Ram - Textiles Exporter</em>
                        </div>
                    </div>

                    <div className='reviewCard'>
                        <div className='iconSection'>
                            <h4> "... On-time delivery and clear communication ..." </h4>
                            <span className="customerReviewSectionLine"></span>

                        </div>
                        <div className='textSection' style={{ height: "13rem" }}>
                            <p>
                                The international ocean shipping service from India to the US was top-notch. Our shipment arrived on time and in excellent condition. The communication throughout the process was clear and helpful. Overall, we were extremely satisfied with the service and would highly recommend it.
                            </p>

                        </div>
                        <div className='customerName'>
                            <em>Rashi - Pillows Exporter</em>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default CustomerReview
