import React, { useState, useEffect } from 'react';
import right from '../../../src/assets/right.png';

function ScrollToTop() {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 100); // Adjust 100 as needed
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div>
      {/* Your content */}
      <div className='scrollButton' onClick={handleScrollToTop} style={{ display: isScrolled ? 'block' : 'none' }}>
        <img style={{ width: '15px', margin: '9px' }} src={right} />
      </div>
    </div>
  );
}

export default ScrollToTop;