import React from 'react'
import handshake from '../../assets/handshake.png';

function Services() {
    return (
        <div className='servicesContainer'>
            <div>
                <h2 className='servicesHeading'>
                    Everything you need to manage your Global Supply Chain on a single platform
                </h2>
                <span className="section-title-line"></span>
            </div>

            {/* <div className='cardWrapperServices'>
                <div className='card' style={{ marginRight: '20px' }}>
                    <div className='iconSection'>
                        <img className='icon' src={handshake} alt="like" />
                        <h4> Amazon FBA and Distribution Centers </h4>
                        <span className="iconSectionLine"></span>

                    </div>
                    <div className='textSection'>
                        <p>
                            Deliver your products to Amazon FBA warehouses through our door-to-door supply chain.
                        </p>
                    </div>
                </div>

                <div className='card'>
                    <div className='iconSection'>
                        <img className='icon' src={handshake} alt="like" />
                        <h4> Walmart Distribution Centers </h4>
                        <span className="iconSectionLine"></span>

                    </div>
                    <div className='textSection'>
                        <p>
                            Ship your inventory to Walmart distribution centers through our efficient supply chain
                        </p>
                    </div>
                </div>


            </div> */}

            <div className='cardWrapper100'>

                <div className='card25'>
                    <div className='iconSection'>
                        <img className='icon' src={handshake} alt="like" />
                        <h4> Ocean FCL </h4>
                        <span className="iconSectionLine"></span>

                    </div>
                    <div className='textSection'>
                        <p>
                            Move and track full container loads through our network of carriers that have global coverage.
                        </p>
                    </div>
                </div>

                <div className='card25'>
                    <div className='iconSection'>
                        <img className='icon' src={handshake} alt="like" />
                        <h4> Ocean LCL </h4>
                        <span className="iconSectionLine"></span>

                    </div>
                    <div className='textSection'>
                        <p>
                            Leverage our consolidation programs to reliably ship cargo with full supply chain visibility.
                        </p>
                    </div>
                </div>

                <div className='card25'>
                    <div className='iconSection'>
                        <img className='icon' src={handshake} alt="like" />
                        <h4> Air Freight </h4>
                        <span className="iconSectionLine"></span>

                    </div>
                    <div className='textSection'>
                        <p>
                            Our reliable network partners ensure your time-sensitive shipments are delivered faster and on time.
                        </p>
                    </div>
                </div>

                <div className='card25'>
                    <div className='iconSection'>
                        <img className='icon' src={handshake} alt="like" />
                        <h4> Customs Clearance </h4>
                        <span className="iconSectionLine"></span>

                    </div>
                    <div className='textSection'>
                        <p>
                            Comply with import and export laws by leveraging our expertise in end-to-end customs clearance.
                        </p>
                    </div>
                </div>


            </div>

            <div className='cardWrapper100'>

                <div className='card25'>
                    <div className='iconSection'>
                        <img className='icon' src={handshake} alt="like" />
                        <h4> Drayage </h4>
                        <span className="iconSectionLine"></span>

                    </div>
                    <div className='textSection'>
                        <p>
                            We support point to point movements across the U.S. via our drayage partners.
                        </p>
                    </div>
                </div>

                <div className='card25'>
                    <div className='iconSection'>
                        <img className='icon' src={handshake} alt="like" />
                        <h4> Trucking </h4>
                        <span className="iconSectionLine"></span>

                    </div>
                    <div className='textSection'>
                        <p>
                            We provide both full-truck load (FTL) and less than truck load (LTL) services to move cargo in a safe and agile manner
                        </p>
                    </div>
                </div>

                <div className='card25'>
                    <div className='iconSection'>
                        <img className='icon' src={handshake} alt="like" />
                        <h4> Warehousing </h4>
                        <span className="iconSectionLine"></span>

                    </div>
                    <div className='textSection'>
                        <p>
                            Position inventory closer to your customers via our partner network storage facilities spread across the world.
                        </p>
                    </div>
                </div>

                <div className='card25'>
                    <div className='iconSection'>
                        <img className='icon' src={handshake} alt="like" />
                        <h4> Cargo Insurance </h4>
                        <span className="iconSectionLine"></span>

                    </div>
                    <div className='textSection'>
                        <p>
                            Safeguard your inventory through our network of insurers that offer low cost premiums with faster service levels.
                        </p>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default Services
