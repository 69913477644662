import React from 'react'
import like from '../../assets/like.svg';
import handshake from '../../assets/handshake.png';
import map from '../../assets/map.png';
import Accordion from '../../genericComponent/Accordion';

function FAQ() {

    const items = [
        {
            title: 'What is Shiprax?',
            content: 'We are a Digital Freight Forwarding and Supply Chain Management Platform. We work closely with our customers, operating at the intersection of cutting-edge technology simplifying global supply chains and a customer obsessed team to deliver a world class Shiprax experience.'
        },
        {
            title: 'Does Shiprax support only the India to US trade lane?',
            content: 'Yes, our services are currently focused on the India to US trade lane. We are rapidly expanding to other trade lanes and geographies in 2023.'
        },
        {
            title: 'How do I get started?',
            content: 'Join our invite only program by sharing your contact details here. We will get in touch with you to get started'
        },
        {
            title: 'Are you hiring?',
            content: 'Yes, we are hiring across Sales, Operations, Product and Engineering roles. Drop us a note at hiring@shiprax.com and we will setup time to chat. Coffee is on us :)'
        },
    ];


    return (
        <div className='FAQContainer'>
            <div>
                <h2 className='FAQHeading'>
                    FREQUENTLY ASKED QUESTIONS
                </h2>
                <span className="section-title-line"></span>
            </div>

            <div className='FAQSection'>

                <Accordion items={items} />

            </div>
        </div>
    )
}

export default FAQ
