import React, { useState, useEffect } from 'react';
import Shipraxbgromoved from '../../assets/Shipraxbgromoved.png';
import MessageOnWhatsApp from '../MessageOnWhatsApp';
import { useNavigate } from 'react-router-dom';

function Header({ headerItem }) {

    const navigate = useNavigate()
    const [isScrolled, setIsScrolled] = useState(false);
    const scrollThreshold = 100;

    useEffect(() => {
        const handleScroll = () => {
            setIsScrolled(window.scrollY > scrollThreshold);
        };

        window.addEventListener('scroll', handleScroll);

        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <div className='wrapper'>
            <header className={isScrolled ? 'scrolled' : 'header'}>
                {/* header content */}
                <div className='headerItems'>

                    {/* logo */}
                    <a  href="" className='logo-container' >
                        <img className="" src={Shipraxbgromoved} alt="shiprax" />
                    </a>

                    {/* items */}
                    {headerItem.map((item) => (
                        <div className={isScrolled ? 'scrolled_items' : 'items'} onClick={item?.event}>{item.name}</div>
                    ))}

                    {/* sign up button */}
                    <button className="btnStyle" onClick={()=>{sessionStorage.clear(); navigate('/Login')}}>Log Out</button>

                </div>
            </header>
        </div>
    );
}

export default Header;
