import axios from 'axios';
import { API_NOTIFICATION_MESSAGES, SERVICE_URLS } from "../constants/config";
import { getAccessToken } from '../components/utils/common-utils';

const API_URL = '';

const axiosInstance = axios.create({
    baseURL: API_URL,
    timeout: 10000,
});

// axiosInstance.interceptors.request.use(
//     config => {
//         if (config.url.includes('/file/uplaods')) {
//             config.headers['content-type'] = "multipart/form-data";
//         }
//         else {
//             config.headers['content-type'] = "application/json";
//         }
//         return config;
//     },
//     error => {
//         // Handle error setting content-type header
//         console.error("Error setting content-type header:", error);
//         return Promise.reject(error);
//     }

// );

axiosInstance.interceptors.request.use({
    function(config) {
        return config;
    },
    function(error) {
        return Promise.reject(error);
    }
})

axiosInstance.interceptors.response.use({
    function(response) {
        // stop global loader hear
        return processResponse(response);
    },

    function(error) {
        return Promise.reject(processError(error));
    }
})

// If Success -> return {isSuccess: true, data: object}
// If Fail -> return {isFailure: true, status: string, msg: string, code: int}

const processResponse = (response) => {
    if (response.status === 200) {
        return { isSuccess: true, data: response.data }
    }
    else {
        return {
            isFailure: true,
            status: response?.status,
            msg: response?.msg,
            code: response?.code
        }
    }
}

const processError = async (error) => {
    if (error.response) {
        // request made and server responded with a status order
        console.log("ERROR IN RESPONSE:", error.toJSON());
        return {
            isError: true,
            msg: API_NOTIFICATION_MESSAGES.responseFailure,
            code: error.response.status
        }
    }
    else if (error.request) {
        // request made but no response was received
        console.log("ERROR IN REQUEST:", error.toJSON());
        return {
            isError: true,
            msg: API_NOTIFICATION_MESSAGES.requestFailure,
            code: ""
        }
    }
    else {
        // something happened in setting up request that triggers an error
        console.log("ERROR IN NETWORK:", error.toJSON());
        return {
            isError: true,
            msg: API_NOTIFICATION_MESSAGES.networkError,
            code: ""
        }
    }
}

const API = {};

for (const [key, value] of Object.entries(SERVICE_URLS)) {
    API[key] = (body, showUploadProgress, showDownloadProgress) =>
        axiosInstance({
            method: value.method,
            url: value.url,
            data: body,
            responseType: value.responseType,
            headers: {
                authorization: getAccessToken(),
            },
            onUploadProgress: function (progressEvent) {
                if (showUploadProgress) {
                    let percentageCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                    showUploadProgress(percentageCompleted);
                }
            },
            onDownloadProgress: function (progressEvent) {
                if (showDownloadProgress) {
                    let percentageCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                    showDownloadProgress(percentageCompleted);
                }
            },
        })
}

export { API };