import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { API } from '../../service/api';
import { getCountries } from 'country-state-picker';

const initialPayload = {
    country: "",
    pinCode: "",
    state: "",
    city: "",
    pickUpAddress: "",
    mobileNumber: "",
    fullName: "",
    email: "",
    productName: "",
    sku: "",
    unitPrice: "",
    quantity: "",
    hsnCode: "",
    productDescription: "",
    productCategory: "",
    deadWeight: "",
    length: "",
    width: "",
    height: "",
}
const CXForm = () => {

    const navigate = useNavigate();

    const [countryData, setCountryData] = useState([])
    const [cxFormData, setCXFormData] = useState(initialPayload)
    console.log('cxFormData: ', cxFormData);


    const [errorMsg, setErrorMsg] = useState("");


    const handleChange = (event) => {

        const fieldName = event.target.name;
        setCXFormData({
            ...cxFormData,
            [fieldName]: event.target.value,
        });

    };

    useEffect(() => {
        setCountryData(getCountries())
    }, [])

    async function handleSubmitForm(e) {
        e.preventDefault()

        const response = await API.abcForm(cxFormData)
        console.log('response: ', response);
    }

    return (
        <>
            <div className='cxFormWrapper'>
                <div className='signUplogoWrapper'>
                    <h2 style={{ fontSize: "27px", marginTop: '10px' }}>Add Buyer's Detail</h2>
                </div>

                <form className='cxform' onSubmit={handleSubmitForm} >
                    <div style={{ marginTop: '20px', display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
                        <div style={{ width: '100%', marginBottom: '20px' }} className='cxHeader'> Where is the order beign delivered to? <span style={{ color: 'lightgrey', fontWeight: 'bold' }}>(Buyer's Address)</span></div>

                        <div style={{ width: '25%' }}>
                            <div>
                                <label htmlFor="country">Country</label>
                                <select value={cxFormData.country} onChange={handleChange} name="country">
                                    <option value={null} key={0}>Select</option>
                                    {countryData && countryData.map((i) => (
                                        <option value={i.name} key={i.code}>{i.name}</option>
                                    ))}

                                </select>
                            </div>
                        </div>

                        <div style={{ width: '25%' }}>
                            <div>
                                <label htmlFor="pinCode">Pin Code</label>
                            </div>
                            <div>
                                <input
                                    type="text"
                                    placeholder="Enter Buyer's Pin Code"
                                    name="pinCode"
                                    value={cxFormData.pinCode}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>

                        <div style={{ width: '25%' }}>
                            <div>
                                <label htmlFor="state">State</label>
                            </div>
                            <div>
                                <input
                                    type="text"
                                    placeholder="Enter Buyer's State"
                                    name="state"
                                    value={cxFormData.state}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>

                        <div style={{ width: '25%' }}>
                            <div>
                                <label htmlFor="city">City</label>
                            </div>
                            <div>
                                <input
                                    type="text"
                                    placeholder="Enter Buyer's City"
                                    name="city"
                                    value={cxFormData.city}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>

                    </div>

                    <div className='cxHeader' style={{ width: '100%', margin: '30px 0px' }}> To whom is the order being delivered? <span style={{ color: 'lightgrey', fontWeight: 'bold' }}>(Buyer's Info)</span></div>
                    <div style={{ width: '25%' }}>
                        <div>
                            <label htmlFor="mobileNumber">Mobile Number</label>
                        </div>
                        <div>
                            <input
                                type="text"
                                placeholder="Enter Buyers Mobile Number"
                                name="mobileNumber"
                                value={cxFormData.mobileNumber}
                                onChange={handleChange}
                            />
                        </div>
                    </div>

                    <div style={{ width: '25%' }}>
                        <div>
                            <label htmlFor="fullName">Full Name</label>
                        </div>
                        <div>
                            <input
                                type="text"
                                placeholder="Enter Full Name"
                                name="fullName"
                                value={cxFormData.fullName}
                                onChange={handleChange}
                            />
                        </div>
                    </div>

                    <div style={{ width: '25%' }}>
                        <div>
                            <label htmlFor="email">Email Id</label>
                        </div>
                        <div>
                            <input
                                type="text"
                                placeholder="Enter Buyer's Email Id"
                                name="email"
                                value={cxFormData.email}
                                onChange={handleChange}
                            />
                        </div>
                    </div>

                    <div style={{ marginTop: '20px', display: 'flex', flexWrap: 'wrap' }}>

                        <div style={{ width: '100%', marginBottom: '20px' }} className='cxHeader'>Product Details</div>
                        <div className='cxSubHeader'>Product 1 </div>
                        {/* <PackageForm /> */}
                        <div style={{ width: '25%' }}>
                            <div>
                                <label htmlFor="productName">Name</label>
                            </div>
                            <div>
                                <input
                                    type="text"
                                    placeholder="Enter Or Search Your Product Name"
                                    name="productName"
                                    value={cxFormData.productName}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>

                        <div style={{ width: '25%' }}>
                            <div>
                                <label htmlFor="sku">SKU</label>
                            </div>
                            <div>
                                <input
                                    type="text"
                                    placeholder="Enter Product SKU"
                                    name="sku"
                                    value={cxFormData.sku}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>

                        <div style={{ width: '25%' }}>
                            <div>
                                <label htmlFor="unitPrice">Unit Price</label>
                            </div>
                            <div>
                                <input
                                    type="number"
                                    name="unitPrice"
                                    value={cxFormData.unitPrice}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>

                        <div style={{ width: '25%' }}>
                            <div>
                                <label htmlFor="quantity">Quantity</label>
                            </div>
                            <div>
                                <input
                                    type="number"
                                    name="quantity"
                                    value={cxFormData.quantity}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>

                        <div style={{ width: '25%' }}>
                            <div>
                                <label htmlFor="hsnCode">HSN Code</label>
                            </div>
                            <div>
                                <input
                                    type="text"
                                    placeholder="Enter Your Product HSN code"
                                    name="hsnCode"
                                    value={cxFormData.hsnCode}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>

                        <div style={{ width: '25%' }}>
                            <div>
                                <label htmlFor="productDescription">Product Description</label>
                            </div>
                            <div>
                                <input
                                    type="text"
                                    placeholder=" Enter Product Description"
                                    name="productDescription"
                                    value={cxFormData.productDescription}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>

                        <div style={{ width: '25%' }}>
                            <div>
                                <label htmlFor="productCategory">Product Category</label>
                            </div>
                            <div>
                                <input
                                    type="text"
                                    placeholder="Enter Product Category"
                                    name="productCategory"
                                    value={cxFormData.productCategory}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                    </div>

                    <div style={{ marginTop: '20px', display: 'flex', flexWrap: 'wrap' }}>

                        <div style={{ width: '100%', marginBottom: '20px' }} className='cxHeader'>Payment Details</div>

                        <div className='cxSubHeader'>Select mode of payment that your buyer has choosen for the order</div>

                        <div style={{ width: '35%' }}>
                            <input style={{ width: '70px' }} type="radio" id="html" name="fav_language" value="HTML" />
                            <label for="html">Prepaid</label>
                        </div>

                    </div>

                    <div style={{ marginTop: '20px', display: 'flex', flexWrap: 'wrap', width: '100%' }}>

                        <div style={{ width: '100%', marginBottom: '20px' }} className='cxHeader'>Package Details</div>

                        <div>
                            <div>
                                <label htmlFor="deadWeight">Dead Weight</label>
                            </div>
                            <div>
                                <input
                                    type="number"
                                    placeholder="00 kg"
                                    name="deadWeight"
                                    value={cxFormData.deadWeight}
                                    onChange={handleChange}
                                />
                            </div>
                            <div style={{ color: 'lightgrey' }}>
                                (Max. 3digits after decimal place)
                                <br />
                                Note:- The maximum chargeable is 0.05kg
                            </div>
                        </div>
                    </div>

                    <div style={{ marginTop: '20px', display: 'flex', flexWrap: 'wrap', width: '100%' }}>

                        <div style={{ width: '100%', marginBottom: '20px' }} className='cxHeader'>Volumetric Weight</div>
                        <div style={{ width: '100%', marginBottom: '20px' }} className='cxSubHeader'>
                            Enter package dimensions to calculate Volumetric Weight
                        </div>

                        <div style={{ width: '33%' }}>
                            <input
                                type="number"
                                placeholder="00 kg"
                                name="length"
                                value={cxFormData.length}
                                onChange={handleChange}
                            />
                        </div>

                        <div style={{ width: '33%' }}>
                            <input
                                type="number"
                                placeholder="00 kg"
                                name="width"
                                value={cxFormData.width}
                                onChange={handleChange}
                            />
                        </div>

                        <div style={{ width: '33%' }}>
                            <input
                                type="number"
                                placeholder="00 kg"
                                name="height"
                                value={cxFormData.height}
                                onChange={handleChange}
                            />
                        </div>

                        <div style={{ color: 'lightgrey' }}>
                            Note: Dimensions should be in centimeters only & values should be graeter than 0.50 cm.
                        </div>

                    </div>

                    <div className='loginbtnWrapper'>
                        <button className='btnStyle' type="submit" style={{ marginRight: '20px', marginTop: '25px' }} >Submit </button>
                    </div>

                    {errorMsg && <div style={{ color: 'red', marginTop: '20px' }}>
                        Please enter all the fields
                    </div>}
                </form >
            </div >
        </>
    )
}

export default CXForm