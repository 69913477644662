import React from 'react';
import share from '../../assets/share.png';

function TrackYourShiprax() {
    return (
        <div className='shipraxTrackConatainer'>
            <div>
                <h2 className='shipraxTrackHeading'>
                    Track Your Shiprax
                </h2>
                <span className="section-title-line"></span>
            </div>
            <div className='trackSection'>
                <input style={{ fontSize: '1rem', marginRight: '10px' }} type="text" placeholder="Please enter your Quote ID e.g. XQFCL12345678" />

                <div className='btnWrapper'>
                    <div className='icon'>
                        <img className="btnIcon" src={share} alt="btn" />
                    </div>
                    <button>
                        Track Shiprax
                    </button>
                </div>
            </div>
        </div>
    )
}

export default TrackYourShiprax
