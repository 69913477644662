import React from 'react';
import Shipraxbgromoved from '../../assets/Shipraxbgromoved.png';
import MessageOnWhatsApp from '../../genericComponent/MessageOnWhatsApp';


function Footer({ onClickAbout, onClickServices, onClickFAQ, onClickTrack }) {

    const handleClick = () => {
        window.open("https://www.facebook.com", "_blank", "width=600,height=400");
    };


    return (
        <>

            <MessageOnWhatsApp />

            <div className='footerContainer'>
                <div style={{ width: '25%' }}>
                    <div className='logo-container' style={{ width: '40%' }}>
                        <img className="" src={Shipraxbgromoved} alt="shiprax" />
                    </div>
                    <p> 68,131A, Shiv mandir Chawk, begumpur, Malviya Nagar, New Delhi - 110017.
                    </p>

                    <p>
                        support@shiprax.com<br />
                        +91 999999999
                    </p>
                </div>

                <div style={{ width: '25%', display: 'flex', flexDirection: 'column', marginLeft: '70px' }}>
                    <h5>QUICK LINKS</h5>
                    <span onClick={onClickAbout} className='footerLink'>About Us</span>
                    <span onClick={onClickServices} className='footerLink'>Services</span>
                    <span onClick={onClickFAQ} className='footerLink'>FAQs</span>
                    <span onClick={onClickTrack} className='footerLink'>Track Your Shiprax</span>
                    <a href="https://www.shiprax.com/Home/Terms" className='footerLink'>Terms of Use</a>
                    <a href="https://www.shiprax.com/Home/Privacy" className='footerLink'>Privacy Policy</a>
                </div>

                <div style={{ width: '25%', display: 'flex', flexDirection: 'column' }}>
                    <h5>SOCIAL</h5>
                    <a href="https://www.facebook.com" target="_blank" className='footerLink'>Facebook</a>
                    <a href="https://www.facebook.com" target="_blank" className='footerLink'>Instagram</a>
                    <a href="https://www.linkedin.com/company/" target="_blank" className='footerLink'>Linkedln</a>
                    <a href="https://twitter.com/i/flow/login" target='blank' className='footerLink'>Twitter</a>
                </div>

                <div style={{ width: '25%' }}>
                    <img src="https://www.xhipment.com/static/media/iso_seal.3b95735b59fdc3a11ea70327d6968530.svg" style={{ width: '50%' }} />
                </div>
            </div>

            <div class="footerContainer2">
                Copyright © Powered By Enite Solutions. All rights reserved
            </div>
        </>
    )
}

export default Footer;
